import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './Timeline.css'; // Import the CSS file

const Timeline = () => {
  return (
    <div className="timeline-container">
      <h2 className='header-timeline'>Our History</h2>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
          date="2016"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        >
          <h3 className="vertical-timeline-element-title">Vin Minerals was founded</h3>
          <h4 className="vertical-timeline-element-subtitle">Vietnam</h4>
          <p>
          The current chairman is Mr. Ngo Minh Tien, an expert in exporting and importing CaCO3 products worldwide.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2018"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        >
          <h3 className="vertical-timeline-element-title">Second factory</h3>
          <h4 className="vertical-timeline-element-subtitle">Nghe An Province, Vietnam</h4>
          <p>
          We are the manufacturer owning mines and quarries in Quy Hop District, Nghe An Province and contemporarily become one of the leading companies in terms of manufacturing CaCO3 powder. Our factories employ the best technology entirely imported from Germany as well as Japan (Whiteness Analyzer MINOLTA CR-200/ CR-210 made in Japan and Particle diffraction MALVERN made in England) and are installed and maintained by well qualified foreign experts under daily-basis strict quality control system that meets European standard. We provide the customers worldwide  the best wide range products that are used as input materials for various industries.

          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2019"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        >
          <h3 className="vertical-timeline-element-title">Southeast Asia market expansion</h3>
          <h4 className="vertical-timeline-element-subtitle"></h4>
          <p>
            We have expanded our markets in operation with Southeast Asia areas such as Indonesia, Thailand, India, Bangladesh, etc.

          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2022"
          iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        >
          <h3 className="vertical-timeline-element-title">Enter Australia market</h3>
          <h4 className="vertical-timeline-element-subtitle">Australia</h4>
          <p>
          Expanding our business capabilities by creating sustainable synergy and officially entered Australia market in 2022. We have rapidly gained popularity and emerged as the highest growing company in the country’s calcium carbonate powder industry.

          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2023"
          iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
        >
          <h3 className="vertical-timeline-element-title">Establishing intimate relation with Korea</h3>
          <h4 className="vertical-timeline-element-subtitle">Korea</h4>
          <p>
          Established intimate relationship with Korea and becoming their consecutive counterpart in supplying Calcium Carbonate powder over the years.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--video"
          date="Present"
          iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
        >
          <h3 className="vertical-timeline-element-title">Watch Our Journey</h3>
          <div className="center-video">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/oT2x7ia3gp4"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Video"
            ></iframe>
          </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
        />
      </VerticalTimeline>
    </div>
  )
}

export default Timeline
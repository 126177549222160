import React, { useEffect, useState} from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import ContactForm from '../../components/ContactForm/ContactForm'
import productCategories from '../../pages/CategoriesProduct/productCategories';
    
const ContactUs = ({FooterContentEN}) => {

    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };
  
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
  
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
  
  
    const HeaderContentEN = {
        listCategory: [
            {
                name: "Home",
                link: "/",
                listSubItem: []
            },
            {
                name: "About Us",
                link: "/aboutus",
                listSubItem: []
            },
            {
                name: "Contact Us",
                link: "/contactus",
                listSubItem: []
            },
            {
                name: "Products",
                listSubItem: productCategories.map(category => [category.title, `/product/${category.route.toLowerCase()}`])
            }
        ]
    };

    const [onClickNav, setOnClickNav] = useState(false);

  const toggleActive = () => {
      setOnClickNav(!onClickNav);
      document.body.style.overflow = onClickNav ? '' : 'hidden';
  }

  return (
    <div>
      <Header HeaderContent={HeaderContentEN} scrollPosition={scrollPosition} onClickNav={onClickNav} toggleActive={toggleActive}/>
      <ContactForm />
        <Footer FooterContent={FooterContentEN}/>
    </div>
    
  )
}

export default ContactUs